import React from "react"
import {
  Container,
  Section,
  SectionDescription,
  SectionTitle,
  SectionInnerWhite,
} from "../../components/Section"
import SubCategoryLandingProductList from "../../components/ProductCardList/SubCategoryLandingProductList"

const SubCategoryProductSection = props => {
  const {
    title,
    description,
    category,
    subCategory,
    data,
    roof,
    openQuoteModal,
  } = props
  return (
    <Section xpt="60px" mpt="40px" pt="30px" xpb="30px" mpb="20px" pb="15px">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionInnerWhite>
          <SectionTitle>{title}</SectionTitle>
          <SectionDescription maxWidth="1496px" mb="40px">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </SectionDescription>
          <SubCategoryLandingProductList
            data={data}
            category={category}
            subCategory={subCategory}
            roof={roof}
            openQuoteModal={openQuoteModal}
          />
        </SectionInnerWhite>
      </Container>
    </Section>
  )
}

export default SubCategoryProductSection
