import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../layouts"
import Seo from "../components/seo"
import {
  Section,
  Container,
  BreadCrumb,
  SectionInnerWhite,
} from "../components/Section"

import {
  AccordionLabel,
  Accordions,
  Accordionsitems,
  AccordionsContent,
  AccordionsContentinner,
  AccordionsTitle,
} from "../components/Accordion"
import RequestModal from "../components/RequestModal"

import SubCategoryHeroSection from "../sections/Category/SubCategoryHeroSection"
import ContentSection from "../sections/Category/ContentSection"
import SubCategoryProductSection from "../sections/Category/SubCategoryProductSection"
import ExpertSection from "../components/Expert"

const SubCategoryLandingTemplate = ({ location, data }) => {
  const [activeTab, setActiveTab] = useState(null)
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)

  const HandleModalOpen = () => {
    setIsQuoteVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }
  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }

  function handleAccodion(key) {
    if (activeTab === key) {
      return setActiveTab(null)
    }
    setActiveTab(key)
  }
  const heroFeatures = {
    title: data.contentfulSubCategoryLanding.heroFeaturesTitle,
    list: data.contentfulSubCategoryLanding.heroFeatures,
  }
  return (
    <Layout isHeader={true} location={location}>
      <Seo
        title={data.contentfulSubCategoryLanding.metaTitle}
        description={
          data.contentfulSubCategoryLanding.metaDescription.metaDescription
        }
      />
      <BreadCrumb>
        <Container maxWidth="100%" pl="3%" pr="3%">
          <Link to="/">Home </Link>
          <Link to={data.contentfulSubCategoryLanding.subCategory.category.url}>
            {data.contentfulSubCategoryLanding.subCategory.category.name}{" "}
          </Link>
          <span>{data.contentfulSubCategoryLanding.subCategory.name}</span>
        </Container>
      </BreadCrumb>
      <SubCategoryHeroSection
        title={data.contentfulSubCategoryLanding.title}
        description={
          data.contentfulSubCategoryLanding.heroDescription.childMarkdownRemark
            .html
        }
        image={data.contentfulSubCategoryLanding.heroImage.gatsbyImageData}
        features={heroFeatures}
        category={data.contentfulSubCategoryLanding.subCategory.category.name}
      />
      <SubCategoryProductSection
        title={data.contentfulSubCategoryLanding.productSectionTitle}
        description={
          data.contentfulSubCategoryLanding.productSectionDescription
            .childMarkdownRemark.html
        }
        data={data.allContentfulProduct.edges}
        category={data.contentfulSubCategoryLanding.subCategory.category.name}
        subCategory={data.contentfulSubCategoryLanding.subCategory.name}
        roof={data.contentfulSubCategoryLanding.roofStyle.name}
        openQuoteModal={HandleModalOpen}
      />
      {data.contentfulSubCategoryLanding.sections.map((item, i) => (
        <ContentSection data={item} />
      ))}
      <ExpertSection
        title={data.contentfulSubCategoryLanding.whyChooseSectionTitle}
        description={
          data.contentfulSubCategoryLanding.whyChooseSectionDescription
        }
      />
      {data.contentfulSubCategoryLanding.faqSectionTitle ? (
        <Section
          xpt="30px"
          mpt="20px"
          pt="15px"
          xpb="60px"
          mpb="40px"
          pb="30px"
          bgColor="transparent"
        >
          <Container maxWidth="100%" pl="3%" pr="3%">
            <SectionInnerWhite xpl="0" xpr="0" mpl="0" mpr="0" pl="0" pr="0">
              <AccordionLabel textAlign="left">
                {data.contentfulSubCategoryLanding.faqSectionTitle}
              </AccordionLabel>
              <Accordions>
                {data.contentfulSubCategoryLanding.faqs.map((item, index) => (
                  <Accordionsitems
                    className={activeTab === index ? "active" : ""}
                    key={index}
                  >
                    <AccordionsTitle onClick={() => handleAccodion(index)}>
                      {item.question} <span></span>
                    </AccordionsTitle>
                    <AccordionsContent className="accordions-content">
                      <AccordionsContentinner>
                        {item.answer}
                      </AccordionsContentinner>
                    </AccordionsContent>
                  </Accordionsitems>
                ))}
              </Accordions>
            </SectionInnerWhite>
          </Container>
        </Section>
      ) : (
        <></>
      )}
      <RequestModal
        location={location}
        isVisible={isQuoteVisible}
        onClose={HandleModalClose}
      />
    </Layout>
  )
}

export default SubCategoryLandingTemplate

export const query = graphql`
  query SubCategoryLandingTemplateQuery($id: String!) {
    contentfulSubCategoryLanding(id: { eq: $id }) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      subCategory {
        category {
          name
          url
        }
        name
        url
      }
      roofStyle {
        name
      }
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(quality: 100)
      }
      heroFeaturesTitle
      heroFeatures {
        content
      }
      productSectionTitle
      productSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      sections {
        title
        sectionDescription {
          childMarkdownRemark {
            html
          }
        }
        sectionFeatures {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100)
          }
        }
        sectionImage {
          gatsbyImageData(quality: 100)
          title
        }
        bottomDescription {
          childMarkdownRemark {
            html
          }
        }
      }
      whyChooseSectionTitle
      whyChooseSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      faqSectionTitle
      faqs {
        question
        answer
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          name
          url
          description {
            childMarkdownRemark {
              html
            }
          }
          category {
            name
          }
          subCategory {
            name
          }
          sku
          roofType {
            name
          }
          width
          length
          height
          price
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          warranty {
            warranty
          }
          otherSpec {
            otherSpec
          }
        }
      }
    }
  }
`
